import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Toughts on Stock UI",
  "tags": "Apple Design",
  "date": "2014-07-13T00:00:00.000Z",
  "link": "http://marco.org/2014/07/11/standard-controls",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "http://inessential.com/2014/07/10/standard_controls"
      }}>{`Brent`}</a>{` and `}<a parentName="p" {...{
        "href": "http://marco.org/2014/07/11/standard-controls"
      }}>{`Marco`}</a>{` (two people I admire and respect) have posted two great pieces on why there are many advantages for using stock UI and how it might replace the need for a designer for most independent developers.`}</p>
    <p>{`This statement assumes two things that don’t sit well with me:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`That stock UI is what is what’s perceived as a well designed UI`}<sup parentName="p" {...{
            "id": "fnref-1"
          }}><a parentName="sup" {...{
              "href": "#fn-1",
              "className": "footnote-ref"
            }}>{`1`}</a></sup>{`, that your app fits the mold of what stock UI allows you to do and that you don’t need to differentiate yourself from all the other apps that look identical to yours.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`That design is just about the pixels on the screen. Stock UI is never going to replace the need for solid content hierarchy, great user experience and good product design`}<sup parentName="p" {...{
            "id": "fnref-2"
          }}><a parentName="sup" {...{
              "href": "#fn-2",
              "className": "footnote-ref"
            }}>{`2`}</a></sup>{`. The `}<em parentName="p">{`look`}</em>{` of an app is the easy part.`}</p>
      </li>
    </ol>
    <p>{`So don’t be fooled by the fact that the default iOS 7 UI elements look fresh `}<em parentName="p">{`today`}</em>{` — it won’t last forever and only addresses the superficial part of design. I still recommend developers try their hands at designing if they don’t have the ressources to hire a full-blown designer, I know lots of developers who are also excellent product designers.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Default controls were acceptable in the first year or so of the App Store, but people got bored of it eventually. I assume the same thing will be true for the iOS 7 look in a few years.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`So many buzzwords.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      